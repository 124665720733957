import { computed, observable, transaction, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { PaginationStore } from "stores/data/pagination.store"
import {
  getAccountDetailsByIdBuffPlay,
  putHasFraudActivityBuffPlay,
} from "../api/transactions-BuffPlay.call"

export type AccountDetailsTypeBuffPlay = {
  id: string,
  email: string,
  isFrozen: boolean,
  hasFraudActivity: boolean,
  isFaceVerified: boolean,
  createdAt: string,
  points: number,
  utmData: any,
};

export class AccountDetailsStoreBuffPlay extends PaginationStore<
  AccountDetailsTypeBuffPlay
> {
  @observable
  private _currentId: string

  @observable
  public loadedPages: number = 1
  @observable
  public isEmptyArr: boolean = false

  @observable
  public _hasFraudActivity: boolean

  @observable
  public _account: AccountDetailsTypeBuffPlay

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
    this.pagination.limit = 20
  }
  public async getData() {
    if (this._currentId) {
      return this.getAccountDetailsById(this._currentId)
    } else {
      return []
    }
  }

  public async getAccountDetailsById(id: string) {
    this.resetFuncAfterSuccess()
    if (this._currentId !== id) {
      this._currentId = id
    }

    console.log("id", id)

    const accountDetails = await this.defaultFunction(
      getAccountDetailsByIdBuffPlay,
      id,
    )
    if (accountDetails) {
      transaction(() => {
        this.isLoading = false
        this._account = accountDetails
      })
    }

    return accountDetails
  }

  public async putHasFraudActivityBuffPlay(accountId: string, hasFraudActivity: boolean) {
    const hasFraud = await this.defaultFunction(putHasFraudActivityBuffPlay, accountId, hasFraudActivity)
    this._hasFraudActivity = hasFraud
  }

  @computed
  get account(): any {
    return this._account
  }

  @computed
  get hasFraudActivity(): any {
    return this._hasFraudActivity
  }
}

