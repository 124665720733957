import React, { FC, useEffect } from "react"
import { Table } from "antd"
import { observer } from "mobx-react"
import Search from "antd/es/input/Search"
import { useStore } from "../../../stores/utils/use-store"
import { useNavigate } from "react-router-dom"
import { Column } from "../../../abstract/types/column.type"
import { notifications } from "../../../notifications"
import { CopyOutlined } from "@ant-design/icons"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { Account } from "../../accounts/store/account.store"

export const AccountsListBuffPlay: FC = observer(() => {
  const { dataStore: { accountStore } } = useStore()

  const navigate = useNavigate()

  const handleRowClick = (value: Account): any => {
    const { id } = value
    navigate(`details/${id}`)
  }

  useEffect(() => {
    accountStore.getAccounts()
  }, [accountStore])

  const columns: Column[] = [
    {
      title: "Account Id",
      dataIndex: "id",
      key: "id",
      render: (id: string) => {
        return (
          <div
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              navigator.clipboard.writeText(id)
              notifications.success("Copied id:" + id)
            }}
            style={{
              background: "#A3A3A3",
              width: 24,
              height: 24,
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <CopyOutlined
              style={{ color: "white", display: "flex", transform: "translate(5px, 2px)" }}
            />
          </div>
        )
      },
      width: 40,
    },
    { title: "Nickname", dataIndex: "nickname", key: "nickname", width: 70 },
    { title: "Login", dataIndex: "login", key: "login", width: 70, ellipsis: true },
    { title: "Email", dataIndex: "email", key: "email", width: 70 },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (el: number) => (+el)?.toFixed(2),
      width: 70,
    },
    {
      title: "Is Confirmed Email",
      dataIndex: "isConfirmedEmail",
      key: "email",
      render: (el: boolean) => (el ? "Yes" : "No"),
      width: 40,
    },
    {
      title: "Is Frozen",
      dataIndex: "isFrozen",
      key: "email",
      render: (el: boolean) => (el ? "Yes" : "No"),
      width: 40,
    },
    {
      title: "Is Closed",
      dataIndex: "isClosed",
      key: "email",
      render: (el: boolean) => (el ? "Yes" : "No"),
      width: 40,
    },
  ]

  const searchHandler = async(value: string) => {
    accountStore.setFilter(value)
  }

  const paginationOptions: TablePaginationConfig = {
    onChange: async(page, pageSize) => {
      accountStore.pagination = {
        page,
        limit: pageSize || accountStore.pagination.limit,
      }
    },
    total: accountStore.total,
    showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
    defaultPageSize: 10,
    current: accountStore.pagination.page,
  }

  return (
    <>
      <Search placeholder="input search text" enterButton onSearch={searchHandler} />
      <Table
        dataSource={accountStore.accounts}
        columns={columns as any}
        rowKey="id"
        pagination={paginationOptions}
        loading={accountStore.isLoading}
        onRow={record => ({
          onClick: () => {
            handleRowClick(record)
          },
        })}
      />
    </>
  )
})
