import {
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  linkDialogPlugin,
  linkPlugin,
  imagePlugin,
  diffSourcePlugin,
  toolbarPlugin,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  ListsToggle,
  InsertImage,
  UndoRedo,
  DiffSourceToggleWrapper,
  MDXEditor,
  MDXEditorProps,
} from "@mdxeditor/editor"
import "@mdxeditor/editor/style.css"
import { useState } from "react"
import { useStore } from "stores/utils/use-store"

type Props = Omit<MDXEditorProps, "plugins">

export const RichEditor = (props: Props) => {
  const { markdown, ...rest } = props
  const { dataStore: { filesStore } } = useStore()
  // storing the initial markdown like this is not ideal, it will not refresh when saved. But atleast it will show some diff even in
  // case where markdown is being updated on every change (and we have such cases).
  const [initialMarkdown] = useState(markdown)

  return (
    <MDXEditor
      markdown={markdown}
      plugins={[
        headingsPlugin(),
        listsPlugin(),
        quotePlugin(),
        thematicBreakPlugin(),
        linkDialogPlugin(),
        linkPlugin(),
        imagePlugin({
          imageUploadHandler: async file => {
            const formData = new FormData()
            formData.append("file", file)
            const url = await filesStore.uploadImageAndGetUrl(formData)

            return url
          },
        }),
        diffSourcePlugin({ diffMarkdown: initialMarkdown, viewMode: "rich-text" }),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <BlockTypeSelect />
              <BoldItalicUnderlineToggles />
              <CreateLink />
              <ListsToggle />
              <InsertImage />
              <UndoRedo />
              <DiffSourceToggleWrapper>
                <UndoRedo />
              </DiffSourceToggleWrapper>
            </>
          ),
        }),
      ]}
      {...rest}
    />)
}
