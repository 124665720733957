import { computed, observable, transaction, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { PaginationStore } from "stores/data/pagination.store"
import {
  getAccountTransactionBuffPlay,
  getGamesBuffPlay,
  getTransactionsBuffPlayTypes,
} from "../api/transactions-BuffPlay.call"

export type AccountTransactionsFilterBuffPlay = {
  lastDate?: string;
  type?: number[];
  limit?: number;
  gameId?: number[];
  createdAtOrder?: string;
  startDate?: string;
  endDate?: string;
};

export type AccountFilterBuffPlay = {
  eventName?: string;
  startDate?: string;
  endDate?: string;
};

export type AccountTransactionsTypeBuffPlay = {
  id: string,
  accountId: string,
  amount: number,
  currency: string,
  description: string,
  metadata: {
    transactionId: string,
    name: string,
    valueUSD: number,
  },
  type: string,
  createdAt: string,
  updatedAt: string,
};

export class AccountTransactionsStoreBuffPlay extends PaginationStore<
  AccountTransactionsTypeBuffPlay,
  AccountTransactionsFilterBuffPlay
> {
  @observable
  private _accountTransactions: AccountTransactionsTypeBuffPlay[] = []
  @observable
  private _currentId: string
  @observable
  public _expectedLength: number

  @observable
  public loadedPages: number = 1
  @observable
  public isEmptyArr: boolean = false

  @observable
  public _lastDate: string

  @observable
  public _availableTypes: Record<string, string>

  @observable
  public _availableGames: Record<string, string>

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
    this.pagination.limit = 20
  }
  public async getData() {
    this.isEmptyArr = false

    if (this._currentId) {
      return this.getAccountTransactions(this._currentId)
    } else {
      return []
    }
  }

  public async getTransactionBuffPlayTypes() {
    const transactionsTypes = await this.defaultFunction(getTransactionsBuffPlayTypes)
    this._availableTypes = transactionsTypes
  }

  public async getGamesBuffPlay() {
    const games = await this.defaultFunction(getGamesBuffPlay, 1, 100)
    this._availableGames = games
  }

  public async getAccountTransactions(id: string) {
    this.resetFuncAfterSuccess()
    if (this._currentId !== id) {
      this._currentId = id
      this._accountTransactions = []
    }
    if (this._accountTransactions.length === 0) {
      this.loadedPages = 1
    }
    this.isEmptyArr = false

    const accountTransactions = await this.defaultFunction(
      getAccountTransactionBuffPlay,
      id,
      this.filter,
      20,
      this.loadedPages,
    )
    if (accountTransactions) {
      transaction(() => {
        this.isLoading = false
        if (accountTransactions.length === 0) {
          this.isEmptyArr = true
          this.loadedPages = 0
        }
        if (this.loadedPages > 1) {
          this._accountTransactions = [...this._accountTransactions, ...accountTransactions]
          this.loadedPages = this.loadedPages + 1
        } else {
          this._accountTransactions = accountTransactions
        }
      })
    }

    return accountTransactions
  }

  @computed
  get accountTransactions(): AccountTransactionsTypeBuffPlay[] {
    return this._accountTransactions
  }

  @computed
  get expectedLength(): number {
    return this._expectedLength
  }

  @computed
  get lastDate(): string {
    return this._lastDate
  }

  @computed
  get availableTypes(): Record<string, string> {
    return this._availableTypes
  }

  @computed
  get availableGames(): any {
    return this._availableGames
  }
}

