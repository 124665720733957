import { useRef, useEffect } from "react"

interface InfiniteScrollProps {
  onLoadMore: () => void;
  isLoading: boolean;
  hasMore: boolean;
}

const InfiniteScroll = ({ onLoadMore, isLoading, hasMore }: InfiniteScrollProps) => {
  const loadMoreRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!hasMore || isLoading) return

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          onLoadMore()
        }
      },
      { threshold: 1.0 },
    )

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current)
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current)
      }
    }
  }, [onLoadMore, isLoading, hasMore])

  return <div ref={loadMoreRef} style={{ height: 20, background: "transparent" }} />
}

export default InfiniteScroll
