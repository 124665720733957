import { computed, observable, transaction, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { PaginationStore } from "stores/data/pagination.store"
import { getAnalyticsBuffPlay, getTransactionsBuffPlayTypes } from "../api/transactions-BuffPlay.call"

export type AccountTransactionsFilterBuffPlay = {
  lastDate?: string;
  eventName?: string;
  limit?: number;
  gameId?: number[];
  createdAtOrder?: string;
  startDate?: string;
  endDate?: string;
};

export type AccountAnalyticsTypeBuffPlay = {
  id: string,
  name: string,
  currentSequence: number,
  progress: number,
};

export class AccountAnalyticsBuffPlayStore extends PaginationStore<
  AccountAnalyticsTypeBuffPlay,
  AccountTransactionsFilterBuffPlay
> {
  @observable
  private _accountAnalytics: AccountAnalyticsTypeBuffPlay[] = []
  @observable
  private _currentId: string

  @observable
  public loadedPages: number = 1
  @observable
  public isEmptyArr: boolean = false

  @observable
  public _availableTypes: Record<string, string>

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
    this.pagination.limit = 20
  }
  public async getData() {
    this.isEmptyArr = false

    if (this._currentId) {
      return this.getAnalyticsBuffPlay(this._currentId)
    } else {
      return []
    }
  }

  public async getAnalyticsBuffPlayTypes() {
    const transactionsTypes = await this.defaultFunction(getTransactionsBuffPlayTypes)
    this._availableTypes = transactionsTypes
  }

  public async getAnalyticsBuffPlay(id: string) {
    this.resetFuncAfterSuccess()
    if (this._currentId !== id) {
      this._currentId = id
      this._accountAnalytics = []
    }
    if (this._accountAnalytics.length === 0) {
      this.loadedPages = 1
    }
    this.isEmptyArr = false

    const accountAnalytics = await this.defaultFunction(
      getAnalyticsBuffPlay,
      id,
      this.loadedPages,
      10,
      this.filter,
    )
    if (accountAnalytics) {
      transaction(() => {
        this.isLoading = false
        if (accountAnalytics.data?.length === 0) {
          this.isEmptyArr = true
          this.loadedPages = 0
        }
        if (this.loadedPages > 1) {
          this._accountAnalytics = [...this._accountAnalytics, ...accountAnalytics.data]
        } else {
          this._accountAnalytics = accountAnalytics.data
        }
        this.total = accountAnalytics.total
      })
    }

    return accountAnalytics.data
  }

  @computed
  get accountAnalytics(): AccountAnalyticsTypeBuffPlay[] {
    return this._accountAnalytics
  }

  @computed
  get availableTypes(): Record<string, string> {
    return this._availableTypes
  }
}
