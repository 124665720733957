import React, { FC, useEffect } from "react"
import { Form, Modal, Select } from "antd"
import { useStore } from "stores/utils/use-store"
import { SubmitCancelButtons, FormElement } from "components"

export const AddBonusBuffPlayComponent: FC<{
  visible: boolean;
  onClose: () => void;
  onSubmit?: (el: { name: string; amount: number, type: number }) => void;
}> = ({ visible, onClose, onSubmit }) => {
  const { dataStore: { accountDetailsStore, accountTransactionsStoreBuffPlay } } = useStore()

  useEffect(() => {
    if (accountTransactionsStoreBuffPlay.availableTypes) {
      accountTransactionsStoreBuffPlay.getTransactionBuffPlayTypes().then()
    }
    accountTransactionsStoreBuffPlay.getGamesBuffPlay().then()
  }, [])

  const onFinishHandler = async(el: { name: string; amount: number, type: number }) => {
    accountDetailsStore.funcAfterSuccess = async() => {
      onClose()
      await accountDetailsStore.getAccountDetails(accountDetailsStore.id)
    }

    await accountDetailsStore.addBonus(el)
  }

  return (
    <Modal visible={visible} title="Create a Transaction" footer={null} onCancel={() => onClose()}>
      <a
        style={{ marginBottom: "15px", display: "inline-block" }}
        href="https://fulcrumrocks.atlassian.net/wiki/spaces/BUFF/pages/649101317/Transaction+Types"
      >
        Documentation link
      </a>
      <Form onFinish={onSubmit || onFinishHandler}>
        {accountTransactionsStoreBuffPlay.availableTypes && (
          <Form.Item
            label="Transaction type"
            name="type"
            rules={[
              {
                required: true,
                message: "Please select type!",
              },
            ]}
          >
            <Select>
              {Object.entries(accountTransactionsStoreBuffPlay.availableTypes).map((item, id) => (
                <Select.Option value={item[1]} key={id}>
                  {item[0] + 1} - {item[1]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {accountTransactionsStoreBuffPlay.availableGames && (
          <Form.Item
            label="Game Id"
            name="gameId"
            initialValue=""
            rules={[
              {
                required: false,
                message: "Please select type!",
              },
            ]}
          >
            <Select>
              {accountTransactionsStoreBuffPlay.availableGames.data.map((item, id) => {
                return (
                  <Select.Option value={item.id} key={id}>
                    {item.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        )}
        <FormElement
          type="inputNumber"
          label="Transaction Amount"
          name="amount"
          required
          props={{ style: { width: "100%" } }}
        />
        <FormElement
          type="input"
          label="Currency"
          name="currency"
          initialValue="POINTS"
          required
          props={{ style: { width: "100%" } }}
        />
        <FormElement
          type="input"
          label="Device Id"
          name="deviceId"
          initialValue=""
          props={{ style: { width: "100%" } }}
        />
        <FormElement
          type="text"
          label="Description"
          name="description"
          required
          props={{ style: { width: "100%" } }}
        />
        <SubmitCancelButtons onClose={onClose} isLoading={accountDetailsStore.isLoading} />
      </Form>
    </Modal>
  )
}
