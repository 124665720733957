import { FC, useEffect, useState } from "react"
import { Form, Input, Radio, Select } from "antd"
import { UploadComponent } from "components"

import { observer } from "mobx-react"
import { notifications } from "notifications"
import { SubmitCancelButtons } from "components"
import { IAdvertiser } from "../api/types"
import { createAdvertiser, updateAdvertiser } from "../api"
import { useNavigate } from "react-router-dom"
import Title from "antd/lib/typography/Title"
import GeoPickerElement from "components/geoPicker"

export const CreateAdvertiser: FC<{
  onClose: (advertiser?: IAdvertiser) => void, initial?: IAdvertiser }> = observer(({ onClose, initial }) => {
    const [image, setImage] = useState(initial?.imageUrl || "")
    const [smallImage, setSmallImage] = useState(initial?.smallImageUrl || "")
    const [submitting, setSubmitting] = useState(false)
    const navigate = useNavigate()

    const onFinishHandler = async(e: IAdvertiser) => {
      const isEdit = !!initial?.id
      if (submitting) return
      if (!e.name) {
        return notifications.error("Please enter the advertiser's name")
      }
      setSubmitting(true)

      e.imageUrl = image
      e.smallImageUrl = smallImage
      if (e.ctaUrl === "") e.ctaUrl = null

      if (isEdit) {
        e.id = initial?.id

        return updateAdvertiser({
          ...initial,
          ...e,
        }).then(updated => {
          notifications.success("Advertiser updated")
          setSubmitting(false)
          close(updated)
        }).catch(e => {
          notifications.error(e.cause.message)
          setSubmitting(false)
        })
      }

      createAdvertiser(e).then(created => {
        notifications.success("Advertiser created")
        setSubmitting(false)
        navigate(`/internal-offerwall/advertisers/${created.id}`)
      }).catch(e => {
        notifications.error(e.cause.message)
        setSubmitting(false)
      })
    }
    const onFinishFailedHandler = (e: any) => {
      console.log(e)
    }

    const close = (advertiser?: IAdvertiser) => {
      (document.getElementById("createAdvertiser") as HTMLFormElement).reset()
      onClose(advertiser)
    }

    useEffect(() => {
      (document.getElementById("createAdvertiser") as HTMLFormElement).reset()
    }, [initial])

    return (
      <Form onFinish={onFinishHandler} onFinishFailed={onFinishFailedHandler} id="createAdvertiser">
        <Form.Item
          label="Name"
          name="name"
          initialValue={initial?.name || ""}
          style={{ width: "100%" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          initialValue={initial?.description || ""}
          style={{ width: "100%" }}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Short description"
          name="shortDescription"
          initialValue={initial?.shortDescription || ""}
          style={{ width: "100%" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Terms"
          name="terms"
          initialValue={initial?.terms || ""}
          style={{ width: "100%" }}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="For Mobile"
          name="forMobile"
          initialValue={initial?.forMobile || false}
          style={{ width: "100%" }}
        >
          <Select>
            <Select.Option value={true}>Yes</Select.Option>
            <Select.Option value={false}>No</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="CTA Text"
          name="ctaText"
          initialValue={initial?.ctaText || ""}
          style={{ width: "100%" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="CTA URL"
          name="ctaUrl"
          initialValue={initial?.ctaUrl || ""}
          style={{ width: "100%" }}
        >
          <Input />
        </Form.Item>
        <GeoPickerElement initialValue={initial?.geoRegions} label="GEO Regions" name="geoRegions" />
        <div style={{ width: "100%", alignItems: "center", justifyContent: "space-between", marginBottom: 8 }}>
          <Form.Item
            label="Type"
            name="type"
            initialValue={initial?.type}
            style={{ width: "100%" }}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={[{ label: "Partner", value: "PARTNER" }, { label: "Buff", value: "BUFF" }]}
            />
          </Form.Item>
        </div>
        <Title level={4}>Advertiser Image</Title>
        <UploadComponent
          image={image}
          style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          setImage={setImage}
          imgStyle={{ width: 72, height: 72, textAlign: "center", display: "flex", flexDirection: "row" }}
          placeholder="Upload item image"
          placeHolderStyle={{ display: "flex", flexDirection: "row" }}
        />
        <Title level={4}>Small Image</Title>
        <UploadComponent
          image={smallImage}
          style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          setImage={setSmallImage}
          imgStyle={{ width: 72, height: 72, textAlign: "center", display: "flex", flexDirection: "row" }}
          placeholder="Upload small item image"
          placeHolderStyle={{ display: "flex", flexDirection: "row" }}
        />
        <SubmitCancelButtons onClose={close} isLoading={submitting} />
      </Form>
    )
  })
