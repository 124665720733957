import {
  AccountFilterBuffPlay,
  AccountTransactionsFilterBuffPlay,
  AccountTransactionsTypeBuffPlay,
} from "../store/account-transactions-BuffPlay.store"
import { apiCall } from "../../../api-calls/api-call"

export const getAccountTransactionBuffPlay = async(
  accountId: string,
  filter: AccountTransactionsFilterBuffPlay,
  limit: number,
  page: number,
): Promise<AccountTransactionsTypeBuffPlay[]> => {
  const query: {
    accountId: string;
    lastDate?: string;
    type?: number[];
    limit?: number;
    page?: number;
    gameId?: number[];
    createdAtOrder?: string;
    startDate?: string;
    endDate?: string;
  } = { limit, accountId, page }
  if (filter && filter.type) query.type = filter.type
  if (filter && filter.lastDate) query.lastDate = filter.lastDate
  if (filter && filter.gameId) query.gameId = filter.gameId
  if (filter && filter.createdAtOrder) query.createdAtOrder = filter.createdAtOrder
  if (filter && filter.startDate) query.startDate = filter.startDate
  if (filter && filter.endDate) query.endDate = filter.endDate

  return apiCall({
    method: "GET",
    path: "buff-play/transactions/all",
    query,
  })
}

export const getAccountGamesBuffPlay = async(accountId: string) => {
  return apiCall({
    method: "GET",
    path: "buff-play/games/progress",
    query: { accountId },
  })
}

export const getAccountDetailsByIdBuffPlay = async(accountId: string) => {
  return apiCall({
    method: "GET",
    path: `buff-play/accounts/${accountId}`,
    query: { accountId },
  })
}

export const getGamesBuffPlay = async(page: number, limit: number) => {
  return apiCall({
    method: "GET",
    path: "buff-play/games",
    query: { page, limit },
  })
}

export const getAnalyticsBuffPlay = async(accountId: string, page: number, limit: number, filter: AccountFilterBuffPlay) => {
  const query: {
    accountId: string;
    page?: number;
    limit?: number;
    eventName?: string;
    startDate?: string;
    endDate?: string;
  } = { limit, accountId, page }
  if (filter && filter.eventName) query.eventName = filter.eventName
  if (filter && filter.startDate) query.startDate = filter.startDate
  if (filter && filter.endDate) query.endDate = filter.endDate

  return apiCall({
    method: "GET",
    path: "analytics/events",
    query,
  })
}

export const getSecurityLogsBuffPlay = async(accountId: string, page: number, limit: number, filter: AccountTransactionsFilterBuffPlay) => {
  const query: {
    accountId: string;
    page?: number;
    limit?: number;
    startDate?: string;
    endDate?: string;
  } = { limit, accountId, page }
  if (filter && filter.startDate) query.startDate = filter.startDate
  if (filter && filter.endDate) query.endDate = filter.endDate

  return apiCall({
    method: "GET",
    path: "security-logs",
    query,
  })
}

export const getTransactionsBuffPlayTypes = async() => {
  return apiCall({
    method: "GET",
    path: "buff-play/transactions/types",
  })
}

export const createTransactionBuffPlay = async(data: any) => {
  return apiCall({
    method: "POST",
    path: "buff-play/transactions",
    body: { ...data, metadata: {} },
  })
}

export const putHasFraudActivityBuffPlay = async(accountId: string, hasFraudActivity: boolean) => {
  return apiCall({
    method: "PUT",
    path: `buff-play/balances/has-fraud-activity/${accountId}`,
    body: { hasFraudActivity },
  })
}
