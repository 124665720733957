import React, { useEffect, useState } from "react"
import { Table } from "antd"
import { useStore } from "stores/utils/use-store"
import { Column } from "abstract/types/column.type"
import { notifications } from "notifications"
import { CopyOutlined } from "@ant-design/icons"
import { useParams } from "react-router-dom"

const AccountGamesBuffPlay = () => {
  const { dataStore: { accountGamesBuffPlayStore } } = useStore()
  const [tableKey, setTableKey] = useState("")
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    if (!id) return

    accountGamesBuffPlayStore.getAccountGames(id).then(() => setTableKey(String(Math.random())))
  }, [accountGamesBuffPlayStore, id])

  const columns: Column[] = [
    {
      title: "Account Id",
      dataIndex: "id",
      key: "id",
      render: (id: string) => {
        return (
          <div
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              navigator.clipboard.writeText(id)
              notifications.success("Copied id:" + id)
            }}
            style={{
              background: "#A3A3A3",
              width: 24,
              height: 24,
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <CopyOutlined
              style={{ color: "white", display: "flex", transform: "translate(5px, 2px)" }}
            />
          </div>
        )
      },
      width: 40,
    },
    { title: "Name", dataIndex: "name", key: "name", width: 70 },
    { title: "Current sequence", dataIndex: "currentSequence", key: "currentSequence", width: 70 },
    { title: "Progress", dataIndex: "progress", key: "progress", width: 70 },
  ]

  return (
    <Table
      dataSource={accountGamesBuffPlayStore.accountGames}
      key={tableKey}
      columns={columns as any}
      rowKey="id"
      loading={accountGamesBuffPlayStore.isLoading}
    />
  )
}

export default AccountGamesBuffPlay
