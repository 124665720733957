import { Collapse, Tag } from "antd"
import { defaultFormat } from "../../../utils/date"
import { notifications } from "../../../notifications"
import { CopyOutlined } from "@ant-design/icons"

export const analyticsColumnsBuffPlay = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id: string) => {
      return (
        <div
          onClick={() => {
            navigator.clipboard.writeText(id)
            notifications.success("Copied id:" + id)
          }}
          style={{
            background: "#A3A3A3",
            width: 24,
            height: 24,
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <CopyOutlined
            style={{ color: "white", display: "flex", transform: "translate(5px, 2px)" }}
          />
        </div>
      )
    },
  },
  {
    title: "Event name",
    dataIndex: "eventName",
    key: "eventName",
  },
  {
    title: "Event timestamp",
    dataIndex: "eventTimestamp",
    key: "eventTimestamp",
    render: (el: Date) => el && <Tag color="green">{defaultFormat(el)}</Tag>,
  },
  {
    title: "Fingerprint",
    dataIndex: "fingerprint",
    key: "fingerprint",
  },
  {
    title: "Product type",
    dataIndex: "productType",
    key: "productType",
  },
  {
    title: "Payload",
    dataIndex: "payload",
    key: "payload",
    render: (data: any) => {
      return (
        <Collapse>
          <Collapse.Panel key="1" header="Show content" style={{ width: "350px" }}>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </Collapse.Panel>
        </Collapse>
      )
    },
  },
  {
    title: "isSyncedToS3",
    dataIndex: "isSyncedToS3",
    key: "isSyncedToS3",
    render: (e: boolean) => String(e),
  },
  {
    title: "Account Id",
    dataIndex: "accountId",
    key: "accountId",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (el: Date) => el && <Tag color="green">{defaultFormat(el)}</Tag>,
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (el: Date) => el && <Tag color="blue">{defaultFormat(el)}</Tag>,
  },
]

export const securityLogsColumnsBuffPlay = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id: string) => {
      return (
        <div
          onClick={() => {
            navigator.clipboard.writeText(id)
            notifications.success("Copied id:" + id)
          }}
          style={{
            background: "#A3A3A3",
            width: 24,
            height: 24,
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <CopyOutlined
            style={{ color: "white", display: "flex", transform: "translate(5px, 2px)" }}
          />
        </div>
      )
    },
  },
  {
    title: "Account Id",
    dataIndex: "accountId",
    key: "accountId",
  },
  {
    title: "URL",
    dataIndex: "url",
    key: "url",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Blocked",
    dataIndex: "isBlocked",
    key: "isBlocked",
    render: (el: boolean) => (el ? "Yes" : "No"),
  },
  {
    title: "IP",
    dataIndex: "ip",
    key: "ip",
  },
  {
    title: "Fingerprint",
    dataIndex: "fingerprint",
    key: "fingerprint",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (el: Date) => el && <Tag color="green">{defaultFormat(el)}</Tag>,
  },
  {
    title: "Advertising Id",
    dataIndex: "advertisingId",
    key: "advertisingId",
  },
  {
    title: "IP Data",
    dataIndex: "ipData",
    key: "ipData",
    render: (data: any) => {
      return (
        <Collapse>
          <Collapse.Panel key="1" header="Show content" style={{ width: "350px" }}>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </Collapse.Panel>
        </Collapse>
      )
    },
  },
  {
    title: "Recaptcha",
    dataIndex: "recaptcha",
    key: "recaptcha",
    render: (data: any) => {
      return (
        <Collapse>
          <Collapse.Panel key="1" header="Show content" style={{ width: "350px" }}>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </Collapse.Panel>
        </Collapse>
      )
    },
  },
  {
    title: "Play integrity",
    dataIndex: "playIntegrity",
    key: "playIntegrity",
    render: (data: any) => {
      return (
        <Collapse>
          <Collapse.Panel key="1" header="Show content" style={{ width: "350px" }}>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </Collapse.Panel>
        </Collapse>
      )
    },
  },
]
