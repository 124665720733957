import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { Button, Checkbox, Input, Popconfirm, Space, Tooltip, Typography } from "antd"
import { FC } from "react"
import styles from "./styles.module.css"
import { RichEditor } from "components/RichEditor/RichEditor"

const { Title } = Typography

export type ConsentRow = {
  defaultChecked?: boolean;
  required?: boolean;
  description: string;
  customId?: string;
}

type Props = {
  rows: ConsentRow[];
  onChange: (consentRows: ConsentRow[]) => void;

  /** Custom IDs are used when saving consent result to the database */
  withCustomId?: boolean;
};

export const UserConsentEditor: FC<Props> = ({ onChange, rows, withCustomId }) => {
  const onChangeRow = (index: number, callback: (row: ConsentRow) => ConsentRow) => {
    const newConsentRows = [...rows]
    newConsentRows[index] = callback(newConsentRows[index])
    onChange(newConsentRows)
  }

  const addNewRow = () => {
    onChange([...rows, { description: "", customId: "" }])
  }

  const deleteRow = (index: number) => {
    const newRows = [...rows]
    newRows.splice(index, 1)
    onChange(newRows)
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {rows.map((row, index) => (
        <div key={index} className={styles.rowWrapper}>
          <Title level={4}>Consent row {index + 1}</Title>
          <div style={{ flexGrow: 1 }}>
            {withCustomId && (
              <div style={{ marginBottom: "1rem" }}>
                <label>
                  Custom ID (do not use whitespace){" "}
                  <Tooltip
                    title={`Custom ID will be the key of the consent row saved in the database when user onboards contest. 
                Use something descriptive, as this will be the only information saved together with the result value (checked or not).`}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </label>
                <Input
                  placeholder="terms_and_conditions_buff"
                  onChange={e => onChangeRow(index, prev => ({
                    ...prev,
                    customId: e.target.value.replace(/\s+/g, ""),
                  }))}
                  value={row.customId}
                  maxLength={128}
                />
              </div>
            )}
            <label>
              Description{" "}
              <Tooltip title="This is the text that will be shown to the user">
                <InfoCircleOutlined />
              </Tooltip>
            </label>
            <RichEditor
              markdown={row.description.trim()}
              onChange={markdown => onChangeRow(index, prev => ({ ...prev, description: markdown.trim() }))}
            />
          </div>
          <div>
            <Checkbox
              checked={row.required}
              onChange={e => onChangeRow(index, prev => ({ ...prev, required: e.target.checked }))}
            >
              Required
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={row.defaultChecked}
              onChange={e => onChangeRow(index, prev => ({ ...prev, defaultChecked: e.target.checked }))}
            >
              Checked by default
            </Checkbox>
          </div>

          <Popconfirm
            title={`Are you sure to delete consent row ${index + 1} ?`}
            onConfirm={() => deleteRow(index)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger type="primary" icon={<DeleteOutlined />} style={{ alignSelf: "end" }}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ))}

      <Button type="primary" onClick={addNewRow}>
        Add new consent row
      </Button>
    </Space>
  )
}
