import React, { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"
import { Button, DatePicker, Select, Table } from "antd"
import moment from "moment"
import s from "./styles.module.css"
import { transactionColumnsBuffPlay } from "../../accounts/components/transaction-columns"

type TypeTags = { title: string; values: number[] };

const transactionsTags: TypeTags[] = []

export const AccountTransactionsBuffPlay: FC = observer<FC>(() => {
  const { id } = useParams<{ id: string }>()

  const { dataStore: { accountTransactionsStoreBuffPlay } } = useStore()
  const [typeFilter, setTypeFilter] = useState<number[]>([])

  const [selectedTags, setselectedTags] = useState<TypeTags[]>([])

  const [startDate, setstartDate] = useState<Date | undefined>(undefined)
  const [endDate, setendDate] = useState<Date | undefined>(undefined)
  const [showAllFilters, setshowAllFilters] = useState(false)

  useEffect(() => {
    setselectedTags(selectedTags.filter(item => item.values.every(v => {
      return typeFilter.includes(v)
    })))
  }, [typeFilter])

  useEffect(() => {
    accountTransactionsStoreBuffPlay.setFilter({
      ...accountTransactionsStoreBuffPlay.filter,
      type: typeFilter,
      startDate: startDate ? startDate.toString() : undefined,
      endDate: endDate ? endDate.toString() : undefined,
      lastDate: undefined,
    })

    accountTransactionsStoreBuffPlay.loadedPages = 1
  }, [typeFilter, startDate, endDate])

  useEffect(() => {
    if (!id) {
      return
    }

    accountTransactionsStoreBuffPlay.getAccountTransactions(id)
    if (!accountTransactionsStoreBuffPlay.availableTypes) {
      accountTransactionsStoreBuffPlay.getTransactionBuffPlayTypes()
    }
  }, [accountTransactionsStoreBuffPlay, id])

  const selectTag = item => {
    let newTags: any = []
    const newArr: number[] = []
    if (selectedTags.includes(item)) {
      newTags = selectedTags.filter(v => {
        return v !== item
      })
      setselectedTags(newTags)

      newTags.map(item => {
        return newArr.push(...item.values)
      })
      setTypeFilter(typeFilter.filter(item => newArr.includes(item)))
    } else {
      newTags = [...selectedTags, item]
      setselectedTags(newTags)
      newTags.map(item => {
        return newArr.push(...item.values)
      })
      setTypeFilter(newArr)
    }
  }

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {transactionsTags.map(item => {
          return (
            <div
              key={item.title}
              className={[s.tag, selectedTags.includes(item) && s.selected]
                .filter(Boolean)
                .join(" ")}
              onClick={() => selectTag(item)}
            >
              {item.title}
            </div>
          )
        })}
      </div>
      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
        <div style={{ display: "grid", gap: "10px" }}>
          <Button onClick={() => setshowAllFilters(!showAllFilters)}>
            {showAllFilters ? "Hide all filters" : "Show all filters"}
          </Button>
          <Button
            onClick={() => {
              setTypeFilter([])
              setselectedTags([])
              setstartDate(undefined)
              setendDate(undefined)
              setshowAllFilters(false)
            }}
            type="text"
          >
            Clear all filters
          </Button>
        </div>
        Filters:{" "}
      </div>

      {showAllFilters && (
        <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
          {accountTransactionsStoreBuffPlay?.availableTypes && (
            <Select
              onChange={setTypeFilter}
              value={typeFilter}
              style={{ marginBottom: "10px", minWidth: "350px" }}
              placeholder="Filter by type"
              allowClear
            >
              {Object.entries(accountTransactionsStoreBuffPlay?.availableTypes).map((item, id) => item?.length > 1 && (
                <Select.Option value={item[1]} key={id}>
                  {item[1]}
                </Select.Option>
              ))}
            </Select>
          )}
          <DatePicker
            showTime
            format="YYYY-MM-DD"
            placeholder="Start date"
            style={{ width: "350px" }}
            name="startDate"
            value={startDate ? moment(startDate) : undefined}
            onChange={e => setstartDate(e?.toDate())}
          />
          <DatePicker
            showTime
            format="YYYY-MM-DD"
            placeholder="End date"
            style={{ width: "350px" }}
            name="endDate"
            value={endDate ? moment(endDate) : undefined}
            onChange={e => setendDate(e?.toDate())}
          />
        </div>
      )}
      <Table
        dataSource={accountTransactionsStoreBuffPlay.accountTransactions}
        columns={transactionColumnsBuffPlay}
        rowKey="id"
        pagination={false}
        loading={accountTransactionsStoreBuffPlay.isLoading}
        onChange={(p, c, sorter: any) => {
          accountTransactionsStoreBuffPlay.setFilter({
            ...accountTransactionsStoreBuffPlay.filter,
            createdAtOrder: sorter.order === "ascend" ? "ASC" : "DESC",
            lastDate: undefined,
          })
          accountTransactionsStoreBuffPlay.loadedPages = 1
        }}
      />
      <div>
        {accountTransactionsStoreBuffPlay.accountTransactions?.length} /{" "}
        {accountTransactionsStoreBuffPlay.accountTransactions?.length % 20 === 0
          ? accountTransactionsStoreBuffPlay.pagination.limit * accountTransactionsStoreBuffPlay.loadedPages
          : accountTransactionsStoreBuffPlay.accountTransactions?.length}{" "}
        <Button
          disabled={
            (accountTransactionsStoreBuffPlay.accountTransactions?.length
              < accountTransactionsStoreBuffPlay.pagination.limit * accountTransactionsStoreBuffPlay.loadedPages
            || accountTransactionsStoreBuffPlay.isEmptyArr) || !(accountTransactionsStoreBuffPlay.accountTransactions?.length % 20 === 0)
          }
          onClick={() => {
            accountTransactionsStoreBuffPlay.loadedPages = accountTransactionsStoreBuffPlay.loadedPages + 1
            id && accountTransactionsStoreBuffPlay.getAccountTransactions(id)
            accountTransactionsStoreBuffPlay.setFilter({
              ...accountTransactionsStoreBuffPlay.filter,
              lastDate: accountTransactionsStoreBuffPlay.lastDate,
            })
          }}
        >
          Load more
        </Button>
      </div>
    </>
  )
})
