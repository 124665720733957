import { computed, observable, transaction, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { PaginationStore } from "stores/data/pagination.store"
import { getSecurityLogsBuffPlay } from "../api/transactions-BuffPlay.call"

export type AccountTransactionsFilterBuffPlay = {
  lastDate?: string;
  type?: number[];
  limit?: number;
  gameId?: number[];
  createdAtOrder?: string;
  startDate?: string;
  endDate?: string;
};
export type AccountSecurityLogsTypeBuffPlay = {
  id: string,
  name: string,
  currentSequence: number,
  progress: number,
};

export class AccountSecurityLogsBuffPlayStore extends PaginationStore<
  AccountSecurityLogsTypeBuffPlay,
  AccountTransactionsFilterBuffPlay
> {
  @observable
  private _accountSecurityLogs: AccountSecurityLogsTypeBuffPlay[] = []
  @observable
  private _currentId: string

  @observable
  public loadedPages: number = 1
  @observable
  public isEmptyArr: boolean = false

  public isLoading: boolean = false

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
    this.pagination.limit = 20
  }
  public async getData() {
    this.isEmptyArr = false

    if (this._currentId) {
      return this.getSecurityLogsBuffPlay(this._currentId)
    } else {
      return []
    }
  }

  public async getSecurityLogsBuffPlay(id: string) {
    this.resetFuncAfterSuccess()
    if (this._currentId !== id) {
      this._currentId = id
      this._accountSecurityLogs = []
    }
    if (this._accountSecurityLogs.length === 0) {
      this.loadedPages = 1
    }
    this.isEmptyArr = false

    const accountSecurityLogs = await this.defaultFunction(
      getSecurityLogsBuffPlay,
      id,
      this.loadedPages,
      10,
      this.filter,
    )
    if (accountSecurityLogs) {
      transaction(() => {
        this.isLoading = false
        if (accountSecurityLogs.data?.length === 0) {
          this.isEmptyArr = true
          this.loadedPages = 0
        }
        if (this.loadedPages > 1) {
          this._accountSecurityLogs = [...this._accountSecurityLogs, ...accountSecurityLogs.data]
        } else {
          this._accountSecurityLogs = accountSecurityLogs.data
        }
        this.total = accountSecurityLogs.total
      })
    }

    return accountSecurityLogs.data
  }

  @computed
  get accountSecurityLogs(): AccountSecurityLogsTypeBuffPlay[] {
    return this._accountSecurityLogs
  }
}
