import React, { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useStore } from "stores/utils/use-store"
import { Spin, Tabs, Button, Card, Checkbox, Tag, Collapse } from "antd"
import { observer } from "mobx-react"
import AccountGamesBuffPlay from "./account-games-BuffPlay"
import { AccountTransactionsBuffPlay } from "./account-transactions-BuffPlay"
import { createTransactionBuffPlay } from "../api/transactions-BuffPlay.call"
import { AddBonusBuffPlayComponent } from "../../accounts/components/add-bonus-BuffPlay"
import { defaultFormat } from "utils/date"
import { AccountAnalyticsBuffPlay } from "./account-analytics-BuffPlay"
import { AccountSecurityLogsBuffPlay } from "./account-security-logs-BuffPlay"

const { TabPane } = Tabs
const { Panel } = Collapse

export const AccountDetailsBuffPlay: FC = observer<FC>(() => {
  const [visibleAddBonusModal, setAddBonusModalVisibility] = useState<boolean>(false)
  const { id } = useParams<{ id: string }>()

  const { dataStore: { accountTransactionsStoreBuffPlay, accountDetailsStoreBuffPlay } } = useStore()

  useEffect(() => {
    if (!id) {
      return
    }
    accountDetailsStoreBuffPlay.getAccountDetailsById(id).then()
  }, [accountDetailsStoreBuffPlay, id])

  if (!accountDetailsStoreBuffPlay.account) return null
  const { email, createdAt, isFaceVerified, isFrozen, points, utmData }
    = accountDetailsStoreBuffPlay.account

  const onCreateSubmit = async(el: { name: string; amount: number; type: number }) => {
    setAddBonusModalVisibility(false)
    accountTransactionsStoreBuffPlay.funcAfterSuccess = async() => {
      if (id) {
        await accountDetailsStoreBuffPlay.getAccountDetailsById(id)
      }
    }
    await createTransactionBuffPlay({ ...el, accountId: id })

    if (id) {
      await accountTransactionsStoreBuffPlay.getAccountTransactions(id)
    }
  }

  const onChangeHasFraudActivity = async() => {
    if (id) {
      await accountDetailsStoreBuffPlay.putHasFraudActivityBuffPlay(
        id,
        !accountDetailsStoreBuffPlay.account?.hasFraudActivity,
      )
      await accountDetailsStoreBuffPlay.getAccountDetailsById(id)
    }
  }

  return (
    <Spin spinning={accountDetailsStoreBuffPlay.isLoading} size="large">
      {!accountDetailsStoreBuffPlay.isLoading ? (
        <Tabs style={{ marginBottom: "20px" }}>
          <TabPane tab="General Info" key={-2}>
            <div
              style={{
                display: "grid",
                gridAutoFlow: "column",
                gridTemplateColumns: "max-content",
              }}
            >
              <Card title="Account Info">
                <p>ID: {id}</p>
                <p>Email: {email}</p>
                <p>
                  <Checkbox
                    style={{ userSelect: "none" }}
                    onChange={onChangeHasFraudActivity}
                    checked={accountDetailsStoreBuffPlay.account?.hasFraudActivity}
                    disabled={accountDetailsStoreBuffPlay.isLoading}
                  >
                    Fraud Activity
                  </Checkbox>
                </p>
                <p>Face verified: {isFaceVerified ? "Yes" : "No"}</p>
                <p>Frozen: {isFrozen ? "Yes" : "No"}</p>
                <p>Points: {points}</p>
                <p>
                  <Tag color="green">Created At: {defaultFormat(createdAt)}</Tag>
                </p>
                <p>
                  UTM Data:{" "}
                  <p>
                    <Collapse>
                      <Panel key="1" header="Show content" style={{ width: "350px" }}>
                        <pre>{JSON.stringify(utmData, null, 2)}</pre>
                      </Panel>
                    </Collapse>
                  </p>
                </p>
              </Card>
            </div>
          </TabPane>
          <TabPane tab="Points Transactions" key="1">
            <AccountTransactionsBuffPlay />
            <Button onClick={() => setAddBonusModalVisibility(true)} style={{ marginTop: 10 }}>
              Create a Transaction
            </Button>
          </TabPane>
          <TabPane tab="Games progress" key="2">
            <AccountGamesBuffPlay />
          </TabPane>
          <TabPane tab="Analytics" key="3">
            <AccountAnalyticsBuffPlay />
          </TabPane>
          <TabPane tab="Security Logs" key="4">
            <AccountSecurityLogsBuffPlay />
          </TabPane>
        </Tabs>
      ) : (
        <div />
      )}
      <AddBonusBuffPlayComponent
        visible={visibleAddBonusModal}
        onSubmit={onCreateSubmit}
        onClose={() => setAddBonusModalVisibility(false)}
      />
    </Spin>
  )
})
