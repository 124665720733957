export const FORTNITE_NOTIFICATIONS = [
  "PROMO",
  "GLHF",
  "WIN",
  "KILL",
  "SURVIVE_5",
  "SURVIVE_10",
  "KNOCKDOWN",
  "TOP_10",
  "RACE_0",
  "RACE_1",
  "RACE_2",
  "RACE_3",
  "RACE_4",
  "RACE_5",
  "RACE_6",
  "RACE_7",
  "RACE_8",
  "RACE_9",
  "RACE_10",
]

export const LOL_NOTIFICATIONS = [
  "PROMO",
  "GLHF",
  "WIN",
  "KILL",
  "ASSIST",
  "INHIBITOR",
  "TURRET",
  "DOUBLE_KILL",
  "COMPLETED_MATCH",
]

export const MINECRAFT_NOTIFICATIONS = [
  "PROMO",
  "GLHF",
  "KILL",
  "SURVIVE_5",
  "SURVIVE_4",
  "SURVIVE_2",
  "ASSIST",
  "WIN",
]

export const VALORANT_NOTIFICATIONS = ["PROMO", "GLHF", "WIN", "KILL", "ASSIST", "HEADSHOT"]
export const FALL_GUYS_NOTIFICATIONS = ["PROMO", "GLHF", "WIN"]
export const ROCKET_LEAGUE_NOTIFICATIONS = [
  "PROMO",
  "GLHF",
  "WIN",
  "GOAL",
  "SHOT_ON_GOAL",
  "ASSIST",
  "SAVE",
  "DEMOLISH",
  "EPIC_SAVE",
]
export const WARZONE_NOTIFICATIONS = ["PROMO", "GLHF", "WIN", "KILL", "ASSIST"]
export const DOTA_NOTIFICATIONS = ["PROMO", "GLHF", "WIN", "KILL", "ASSIST"]
export const HALO_NOTIFICATIONS = ["PROMO", "GLHF", "KILL", "ASSIST"]
export const HEARTHSTONE_NOTIFICATIONS = ["PROMO", "GLHF", "WIN"]
export const SPLITGATE_NOTIFICATIONS = ["PROMO", "GLHF", "WIN", "KILL", "ASSIST", "HEADSHOT"]
export const R6_NOTIFICATIONS = ["PROMO", "GLHF", "WIN", "ROUND_WIN", "KILL", "ASSIST", "HEADSHOT"]
export const CALL_OF_DUTY_MW2_NOTIFICATIONS = ["GLHF", "PROMO", "RECURRING", "VICTORY"]
export const CALL_OF_DUTY_MW3_NOTIFICATIONS = ["GLHF", "PROMO", "RECURRING", "VICTORY"]
export const CALL_OF_DUTY_WARZONE2_NOTIFICATIONS = ["GLHF", "PROMO", "RECURRING", "VICTORY"]
export const STREET_FIGHTER_6_NOTIFICATIONS = ["GLHF", "PROMO", "ROUND_START", "ROUND_END", "WIN"]

export const GAME_NOTIFICATIONS = {
  5426: LOL_NOTIFICATIONS,
  21216: FORTNITE_NOTIFICATIONS,
  8032: MINECRAFT_NOTIFICATIONS,
  21640: VALORANT_NOTIFICATIONS,
  7314: DOTA_NOTIFICATIONS,
  42424: FALL_GUYS_NOTIFICATIONS,
  10798: ROCKET_LEAGUE_NOTIFICATIONS,
  21854: HALO_NOTIFICATIONS,
  9898: HEARTHSTONE_NOTIFICATIONS,
  10826: R6_NOTIFICATIONS,
  21626: WARZONE_NOTIFICATIONS,
  22328: CALL_OF_DUTY_MW2_NOTIFICATIONS,
  23424: CALL_OF_DUTY_MW3_NOTIFICATIONS,
  223281: CALL_OF_DUTY_WARZONE2_NOTIFICATIONS,
  21404: SPLITGATE_NOTIFICATIONS,
  22894: STREET_FIGHTER_6_NOTIFICATIONS,
}
