import { computed, observable, transaction, makeObservable } from "mobx"
import { RootStore } from "stores/root.store"
import { PaginationStore } from "stores/data/pagination.store"
import { getAccountGamesBuffPlay } from "../api/transactions-BuffPlay.call"

export type AccountTransactionsFilterBuffPlay = {
  lastDate?: string;
  type?: number[];
  limit?: number;
  gameId?: number[];
  createdAtOrder?: string;
  startDate?: string;
  endDate?: string;
};
export type AccountGamesTypeBuffPlay = {
  id: string,
  name: string,
  currentSequence: number,
  progress: number,
};

export class AccountGamesBuffPlayStore extends PaginationStore<
  AccountGamesTypeBuffPlay,
  AccountTransactionsFilterBuffPlay
> {
  @observable
  private _accountGames: AccountGamesTypeBuffPlay[] = []
  @observable
  private _currentId: string

  @observable
  public loadedPages: number = 1
  @observable
  public isEmptyArr: boolean = false

  constructor(rootStore: RootStore) {
    super(rootStore)
    makeObservable(this)
    this.pagination.limit = 20
  }
  public async getData() {
    this.isEmptyArr = false

    if (this._currentId) {
      return this.getAccountGames(this._currentId)
    } else {
      return []
    }
  }

  public async getAccountGames(id: string) {
    this.resetFuncAfterSuccess()
    if (this._currentId !== id) {
      this._currentId = id
      this._accountGames = []
    }
    if (this._accountGames.length === 0) {
      this.loadedPages = 1
    }
    this.isEmptyArr = false

    const accountGames = await this.defaultFunction(
      getAccountGamesBuffPlay,
      id,
    )
    if (accountGames) {
      transaction(() => {
        this.isLoading = false
        if (accountGames.data?.length === 0) {
          this.isEmptyArr = true
          this.loadedPages = 0
        }
        if (this.loadedPages > 1) {
          this._accountGames = [...this._accountGames, ...accountGames.data]
        } else {
          this._accountGames = accountGames.data
        }
        this.total = accountGames.total
      })
    }

    return accountGames.data
  }

  @computed
  get accountGames(): AccountGamesTypeBuffPlay[] {
    return this._accountGames
  }
}
