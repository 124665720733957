import React, { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"
import { Button, DatePicker, Table } from "antd"
import { securityLogsColumnsBuffPlay } from "./columns"
import moment from "moment/moment"
import InfiniteScroll from "../../../components/InfiniteScroll"

const getCurrentDateAndStartOfYear = (): { currentDate: Date; startOfYear: Date } => {
  const currentDate = new Date()
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1)

  return {
    currentDate: currentDate,
    startOfYear: startOfYear,
  }
}

export const AccountSecurityLogsBuffPlay: FC = observer<FC>(() => {
  const { id } = useParams<{ id: string }>()
  const { currentDate, startOfYear } = getCurrentDateAndStartOfYear()

  const [startDate, setstartDate] = useState<Date | undefined>(startOfYear)
  const [endDate, setendDate] = useState<Date | undefined>(currentDate)

  const { dataStore: { accountSecurityLogsBuffPlayStore } } = useStore()

  useEffect(() => {
    accountSecurityLogsBuffPlayStore.setFilter({
      ...accountSecurityLogsBuffPlayStore.filter,
      startDate: startDate ? startDate.toISOString() : startOfYear.toISOString(),
      endDate: endDate ? endDate.toISOString() : currentDate.toISOString(),
    })

    accountSecurityLogsBuffPlayStore.loadedPages = 1
  }, [startDate, endDate])

  useEffect(() => {
    if (!id) {
      return
    }

    accountSecurityLogsBuffPlayStore.getSecurityLogsBuffPlay(id)
  }, [accountSecurityLogsBuffPlayStore, id])

  return (
    <>
      <div style={{ height: 40, display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
        Filters:
        <DatePicker
          showTime
          format="YYYY-MM-DD"
          placeholder="Start date"
          style={{ width: "350px" }}
          name="startDate"
          value={startDate ? moment(startDate) : undefined}
          onChange={e => setstartDate(e?.toDate())}
        />
        <DatePicker
          showTime
          format="YYYY-MM-DD"
          placeholder="End date"
          style={{ width: "350px" }}
          name="endDate"
          value={endDate ? moment(endDate) : undefined}
          onChange={e => setendDate(e?.toDate())}
        />
      </div>
      <div>
        <Button
          onClick={() => {
            setstartDate(startOfYear)
            setendDate(currentDate)
          }}
          type="text"
          style={{ padding: 0 }}
        >
            Clear all filters
        </Button>
      </div>
      <Table
        dataSource={accountSecurityLogsBuffPlayStore?.accountSecurityLogs || []}
        columns={securityLogsColumnsBuffPlay}
        rowKey="id"
        pagination={false}
        loading={accountSecurityLogsBuffPlayStore.isLoading}
        onChange={(p, c, sorter: any) => {
          accountSecurityLogsBuffPlayStore.setFilter({
            ...accountSecurityLogsBuffPlayStore.filter,
            createdAtOrder: sorter.order === "ascend" ? "ASC" : "DESC",
            lastDate: undefined,
          })
          accountSecurityLogsBuffPlayStore.loadedPages = 1
        }}
      />

      <InfiniteScroll
        onLoadMore={() => {
          accountSecurityLogsBuffPlayStore.loadedPages += 1
          id && accountSecurityLogsBuffPlayStore.getSecurityLogsBuffPlay(id)
        }}
        isLoading={accountSecurityLogsBuffPlayStore.isLoading}
        hasMore={
          accountSecurityLogsBuffPlayStore.accountSecurityLogs?.length < accountSecurityLogsBuffPlayStore.total
          && !!accountSecurityLogsBuffPlayStore.total
        }
      />
    </>
  )
})
