import React, { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useStore } from "stores/utils/use-store"
import { observer } from "mobx-react"
import { Button, DatePicker, Table } from "antd"
import { analyticsColumnsBuffPlay } from "./columns"
import moment from "moment/moment"
import Search from "antd/es/input/Search"
import InfiniteScroll from "../../../components/InfiniteScroll"

export const AccountAnalyticsBuffPlay: FC = observer<FC>(() => {
  const { id } = useParams<{ id: string }>()

  const { dataStore: { accountAnalyticsBuffPlayStore } } = useStore()
  const [eventNameFilter, setEventNameFilter] = useState<string | undefined>(undefined)
  const [startDate, setstartDate] = useState<string | undefined>(undefined)
  const [endDate, setendDate] = useState<string | undefined>(undefined)

  const searchHandler = async(value: string) => {
    setEventNameFilter(value)
  }

  useEffect(() => {
    accountAnalyticsBuffPlayStore.setFilter({
      ...accountAnalyticsBuffPlayStore.filter,
      eventName: eventNameFilter,
      startDate: startDate ? startDate.toString() : undefined,
      endDate: endDate ? endDate.toString() : undefined,
    })
    accountAnalyticsBuffPlayStore.loadedPages = 1
  }, [eventNameFilter, startDate, endDate])

  useEffect(() => {
    if (!id) {
      return
    }
    accountAnalyticsBuffPlayStore.getAnalyticsBuffPlay(id)
    if (!accountAnalyticsBuffPlayStore.availableTypes) {
      accountAnalyticsBuffPlayStore.getAnalyticsBuffPlayTypes()
    }
  }, [accountAnalyticsBuffPlayStore, id])

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", height: "40px", gap: 10 }}>
        Filters:
        <DatePicker
          showTime
          format={date => date.utc().format("YYYY-MM-DD HH:mm:ss")}
          placeholder="Start date"
          style={{ width: "350px" }}
          name="startDate"
          value={startDate ? moment(startDate) : undefined}
          onChange={e => {
            if (e) {
              setstartDate(moment(e, "YYYY-MM-DD HH:mm:ss").toISOString())
            }
          }}
        />
        <DatePicker
          showTime
          format={date => date.utc().format("YYYY-MM-DD HH:mm:ss")}
          placeholder="End date"
          style={{ width: "350px" }}
          name="endDate"
          value={endDate ? moment(endDate) : undefined}
          onChange={e => {
            if (e) {
              setendDate(moment(e, "YYYY-MM-DD HH:mm:ss").toISOString())
            }
          }}
        />
        <Search
          style={{ width: "500px" }}
          placeholder="Input event name"
          enterButton
          onSearch={searchHandler}
        />
      </div>
      <div>
        <Button
          style={{ padding: 0 }}
          onClick={() => {
            setEventNameFilter(undefined)
            setstartDate(undefined)
            setendDate(undefined)
          }}
          type="text"
        >
          Clear all filters
        </Button>
      </div>
      <Table
        dataSource={accountAnalyticsBuffPlayStore.accountAnalytics || []}
        columns={analyticsColumnsBuffPlay}
        rowKey="id"
        pagination={false}
        loading={accountAnalyticsBuffPlayStore.isLoading}
        onChange={(p, c, sorter: any) => {
          accountAnalyticsBuffPlayStore.setFilter({
            ...accountAnalyticsBuffPlayStore.filter,
            createdAtOrder: sorter.order === "ascend" ? "ASC" : "DESC",
            lastDate: undefined,
          })
          accountAnalyticsBuffPlayStore.loadedPages = 1
        }}
      />
      <InfiniteScroll
        onLoadMore={() => {
          accountAnalyticsBuffPlayStore.loadedPages += 1
          id && accountAnalyticsBuffPlayStore.getAnalyticsBuffPlay(id)
        }}
        isLoading={accountAnalyticsBuffPlayStore.isLoading}
        hasMore={
          accountAnalyticsBuffPlayStore.accountAnalytics?.length < accountAnalyticsBuffPlayStore.total
          && !!accountAnalyticsBuffPlayStore.total
        }
      />
    </>
  )
})
